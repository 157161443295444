<template>
    <div class="content-body">
        <div class="row mg-0">
            <div class="col-sm-5">
                <div class="content-header pd-l-0">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="#">Configuration</a>
                                </li>
                                <li class="breadcrumb-item text-capitalize active" aria-current="page">Permission</li>
                            </ol>
                        </nav>
                        <h4 class="content-title content-title-xs">Permission Management</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-0 tx-right col-lg-7">
                <button type="button" class="btn btn-sm btn-primary mg-t-10 mg-r-0 mg-b-10" @click="$store.dispatch('modalOpen','addNewPermission')">New Permission</button>
            </div>
            <!-- col -->
        </div>
        <!-- row -->
        <div class="bg-theam-secondary table-search-head">
            <div class="row">
                <div class="filter-input col-md-9 d-flex mr-0 pr-0">
                    <select v-model="filter.size">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
                <div class="col-md-3 ml-0 pl-0">
                    <input type="text" v-model="filter.key"  class="float-right" placeholder="Search...">
                </div>
            </div>
        </div>
        <div class="row py-0">
            <div class="col-md-12">
                <table class="table table2 table-hover" id="tableData">
                    <thead>
                    <tr>
                        <th scope="col" class="text-center">S.N.</th>
                        <th scope="col" class="text-center">Permission Name</th>
                        <th scope="col" class="text-center">Permission Key</th>
                        <th scope="col" class="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody v-for="(permission,index) in dataSets" :key="index">
                    <tr v-if="!removeList.includes(permission.id)">
                        <th scope="row" class="text-center">{{++index}}</th>
                        <td class="text-center">{{permission.name}}</td>
                        <td class="text-center">{{permission.permission_key}}</td>

                        <td class="text-center" >
                            <a href="javascript:;" class="mr-3">
                                <i class="fa fa-edit"></i>
                            </a>
                            <a href="javascript:;" @click="drop(permission.id)">
                                <i class="fa fa-trash"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <Pagination />
                <permissionCreate />
            </div>
        </div>
        <Create />
        <Edit />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import permissionCreate from './create';
    import {Search} from "../../../../mixins/search";
    import Pagination from "../../components/pagination/pagination";

    export default {
        mixins: [Search],
        components: { permissionCreate, Pagination},
        methods: {
            edit(modal, id) {
                this.$store.commit("setDataId", id);
                this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
            },
            drop(id) {
                this.$store.commit("setDataId", id);
                this.$store.commit('dropRecord','api/permissions/'+id);
            },getData(){
                this.$store.commit("getData", `api/permissions/size/${this.filter.size}`);
            }
        },
        computed: {
            ...mapGetters([
                "icons",
                "dataId",
                "dataLists",
                "removeList",
                "pagination",
                "eventMessage",
            ]),
        },
        mounted() {
            this.getData();
        },
        watch: {
            eventMessage(value) {
                if (value.indexOf("deleted success") >= 0) {
                    this.$store.commit("setRemoveList", this.dataId);
                }
            },
        },
        destroyed() {
            this.$store.dispatch("destroyEvent");
        },
    };
</script>