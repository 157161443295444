<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalState" :class="modalId=='addNewPermission'?'show':''" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">New Permission</h5>
                        <a class="close"  @click="$store.dispatch('modalClose','addNewPermission')">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="roleCreate" method="post" class="bg-theam-secondary pt-4" enctype="multipart/form-data">
                        <div class="modal-body row">
                            <div class="col-md-12">
                                <div class="group">
                                    <div class="group-caption">Basic Information</div>
                                    <div class="row mb-2">
                                        <div class="col-md-2">Name<i class="error">*</i></div>
                                        <div class="col-md-4">
                                            <input type="text" v-model="formData.name" id="name" class="form-control">
                                        </div>
                                        <div class="col-md-2">Key<i class="error">*</i></div>
                                        <div class="col-md-4">
                                            <input type="text" v-model="formData.permission_key" id="name" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right mt-3">
                                <button class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import { mapGetters } from "vuex";

export default {
    data(){
        return{
            formData:{
                name:'',
                permission_key: ''
            }
        }
    },
     computed: {
            ...mapGetters(["processing", "eventMessage","dataLists","modalId","modalState"]),
        },
        mounted() {
          this.$store.commit('getData','api/permissions');
        },
    methods:{
        roleCreate(){
             this.$store.commit('setApiUrl','api/permissions/store');
             this.$store.commit('addData',this.formData);
        },
    },
    watch:{
        eventMessage(value){
            if(value.indexOf("success") >= 0){
                this.formData.name = '';
                this.formData.permission_key = '';
                this.$store.dispatch('modalClose','addNewPermission');
            }
        },

    }
}
</script>